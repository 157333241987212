@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  body {
    @apply bg-white dark:bg-black;
  }

  h1 {
    @apply text-center text-3xl bg-mauve py-2 text-cultured mb-2 font-bold;
  }

  h2 {
    @apply text-center text-5xl font-bold my-5;
  }

  h3 {
    @apply font-medium text-2xl my-4;
  }

  h4 {
    @apply font-bold text-xl my-4 text-celadon;
  }

  main {
    @apply px-4 pb-10;

    blockquote {
      margin: auto 1.5em;
      font-style: italic;
    }
  }

  footer {
    @apply px-8 pb-4;

    p {
      @apply text-sm;
    }
  }

  p {
    @apply my-5 font-serif text-lg leading-8;
  }

  a {
    @apply text-mauve font-medium underline;
  }

  ul {
    @apply ml-5 my-4;

    li {
      @apply list-disc font-serif text-lg leading-8;
    }
  }

  input[type=text], input[type=email], input[type=tel], textarea {
    @apply dark:text-black;
  }
}

.notice {
  @apply bg-celadon text-white border-celadon-dark/50 border-t-2 border-b-2 py-2 px-4 font-medium;
}

.alert {
  @apply bg-carrot text-chocolate border-carrot-dark/50 border-t-2 border-b-2 py-2 px-4 font-medium;
}

.fleuron {
  @apply my-12 text-center opacity-50;
}

#topic_copy {
  height: 30rem;
}
